* {
    margin: 0;
    padding: 0;
    outline: 0;
}
img {
    border: none;
}
a {
    color: inherit;
    text-decoration: none;
}
.group:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}
.group {
    display: inline-block;
    clear: both;
}
/* start commented backslash hack \*/
* html .group {
    height: 1%;
}
.group {
    display: block;
}
/* close commented backslash hack */
html, body {
    height: 100%;
}
body {
    font-weight: 400;
    color: #000;
    background: #fff;
}
.right {
    float: right;
}
.left {
    float: left;
}
.uppercase {
    text-transform: uppercase;
}
.wrapper {
    width: 1100px;
    margin: 0 auto;
    position: relative;
}
.text--center {
    text-align: center;
}
.text--bold {
    font-weight: 600;
}
.text--white {
    color: #fff;
}
.text--basic {
    color: #022a68;
}
.text--aqua {
    color: #00a0dc;
}
.text--error {
    color: #ff4242;
}
.body {
    position: relative;
    height: 100%;
}
.main-container {
    height: 100%;
    position: relative;
}
.header {
    position: absolute;
    width: 100%;
    transition: all .4s ease-in-out;
}
.header.header--fixed {
    position: fixed;
    top: 0;
    z-index: 2;
}
.section {
    padding: 80px 0;
    transition: all .4s ease-in-out;
}
.section--small-pading {
    padding: 20px 0;
}
.top-section {
    background: url('../img/top-img.jpg') no-repeat 0 0;
    background-size: cover;
    background-attachment: fixed;
    height: 100vh;
}
.section--white {
    background: #fff;
}
.section--basic {
    background: #002a68;
}
.section--gray {
    background: #ced9de;
}
.logo {
    padding: 2px 0 0;
}
.logo--big {
    margin-top: 200px;
    width: 55%;
}
.hamburger {
    display: none;
    padding: 17px 0;
}
.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    background-color: #fff;
}
.main-menu > ul > li {
    list-style: none;
    float: left;
    padding: 20px 0;
}
.main-menu > ul > li > a {
    color: #fff;
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    padding: 20px 0 20px 20px;
    transition: all .3s ease-in-out;
    -webkit-transition: all .3s ease-in-out;
}
.main-menu > ul > li > a:hover,
.main-menu > ul > li > a.active {
    color: #00a0dc;
}
.cbp-spmenu {
    background: #009fdb;
    position: fixed;
}
.cbp-spmenu.mobile {
    top: 66px;
}
.cbp-spmenu li {
    list-style: none;
}
.cbp-spmenu a {
    display: block;
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    font-family: "Open Sans";
    transition: all .4s ease-in-out;
}
.cbp-spmenu a:hover {
    color: #002a68;
}
.cbp-spmenu a:active {
    color: #002a68;
}
.cbp-spmenu-vertical {
    width: 180px;
    height: 100%;
    top: 0;
    z-index: 1000;
}
.cbp-spmenu-vertical a {
    border-bottom: 1px solid #fff;
    padding: 1em;
}
.cbp-spmenu-right {
    right: -180px;
}
.cbp-spmenu-right.cbp-spmenu-open {
    right: 0px;
    box-shadow: -1px 0px 4px #464646;
}
.cbp-spmenu-push {
    overflow-x: hidden;
    position: relative;
    left: 0;
}

.cbp-spmenu-push-toleft {
    left: -180px;
}
.cbp-spmenu,
.cbp-spmenu-push {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
h1 {
    position: relative;
    font-size: 32px;
    line-height: 36px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding-bottom: 20px;
    margin-bottom: 40px;
}
h1:before {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    bottom: 0;
    left: 47%;
    border-bottom: 2px solid #00a0dc;
}
h2 {
    position: relative;
    font-size: 16px;
    line-height: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}
p {
    font-size: 16px;
    line-height: 26px;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 20px;
}
p a {
    color: #022a68;
    border-bottom: 1px dotted #002a68;
}
.icon__holder {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin: 20px auto;
}
.icon--lamp {
    background: url('../img/lamp.svg') #00a0dc no-repeat center center;
}
.icon--brain {
    background: url('../img/brain.svg') #00a0dc no-repeat center center;
}
.icon--hands {
    background: url('../img/hands.svg') #00a0dc no-repeat center center;
}
.icon--link {
    background: url('../img/link.svg') #00a0dc no-repeat center center;
}
.team__member {
    padding: 0;
    min-height: 480px;
    width: 20%;
    border: 10px solid #fff;
}
.team__member--christian {
    background: url('../img/christian.png') no-repeat top center;
    background-size: cover;
}
.team__member--konstantin {
    background: url('../img/konstantin.jpg') no-repeat top center;
    background-size: cover;
}
.team__member--torsten {
    background: url('../img/torsten.jpg') no-repeat top center;
    background-size: cover;
}
.team__member--andrew {
    background: url('../img/andrew.jpg') no-repeat top center;
    background-size: cover;
}
.team__member--modesto {
    background: url('../img/modesto.jpg') no-repeat top center;
    background-size: cover;
}
.team__member .box {
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    height: 480px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
.team__member .box:hover {
    background: rgba(255, 255, 255, .6);
}
.team__member .box .team__member__info {
    position: absolute;
    top: 15px;
    width: 100%;
    padding: 10px 0;
    opacity: 0;
    transition: all .4s ease-in-out;
}
.team__member .box:hover .team__member__info {
    opacity: 1;
}
.team__member__name {
    font-size: 20px;
    line-height: 24px;
    padding-bottom: 5px;
}
.team__member__position {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 2px solid #00a0dc;
    display: inline-block;
}
.team__member__bio {
    font-weight: 400;
    color: #002a68;
    font-size: 15px;
    line-height: 24px;
    padding: 0 15px;
    text-align: justify;
}

.btn {
    padding: 15px 35px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
.btn--blue {
    color: #fff;
    border: 2px solid #002a68;
    background-color: #002a68;
}
.btn--blue:hover {
    color: #002a68;
    background: transparent;
}
.btn--rounded {
    border-radius: 100px;
}

.contacts__holder {
    box-shadow: 6px 7px 20px rgba(155, 155, 155, 0.5);
}
.contacts__holder__logo {
    padding: 120px 0;
}
.contacts__holder__form {
    padding: 40px 20px;
}
.field__group {
    margin-bottom: 30px;
}
.field__input, textarea {
    background-color: #eee;
    border-radius: 100px;
    color: #999;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    border: 1px solid #eee;
    padding: 20px 55px 20px 20px;
    width: 100%;
    box-sizing: border-box;
}
textarea {
    border-radius: 30px;
    resize: none;
}
.field__input--name {
    background: url('../img/icon-user.png') #eee no-repeat center right;
    background-size: 40px 20px;
}
.field__input--email {
    background: url('../img/icon-email.png') #eee no-repeat center right;
    background-size: 40px 15px;
}
.field__input.error {
    border: 1px solid #ff4242;
}
.contact__resp {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    text-align: left;
    transition: all .4s ease-in-out;
}

.hidden {
    display: none;
}
.non-visible {
    opacity: 0;
}

.footer {
    padding: 20px 0;
}
.footer--sticky {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.footer__copyright {
    font-size: 14px;
    line-height: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #fff;
}

.mt20{
    margin-top: 20px;
}
.mb20{
    margin-bottom: 20px;
}
.mb0 {
    margin-bottom: 0;
}

@media screen and (max-width: 1100px) {
    .logo--big {
        margin-top: 120px;
        width: 70%;
    }
    .logo--vert {
        width: 80%;
    }
    .wrapper {
        width: 100%;
    }
    .row {
        margin: 0;
    }
    .team__member {
        width: 100%;
        min-height: 400px;
        background-size: 60%;
    }
    /*.team__member--christian {
        background-size: inherit;
    }
    .team__member--konstantin {
        background-size: inherit;
    }
    .team__member--torsten {
        background-size: inherit;
    }
    .team__member--andrew {
        background-size: inherit;
    }
    .team__member--modesto {
        background-size: inherit;
        height: 400px;
    }*/
    .team__member .box {
        background: transparent;
        height: auto;
    }
    .team__member .box:hover {
        background: transparent;
    }
    .team__member .box .team__member__info {
        opacity: 1;
        position: relative;
        margin-top: 340px;
        margin-bottom: 20px;
    }
    .team__member__name {
        font-size: 18px;
    }
    .team__member__position {
        padding-bottom: 10px;
    }
    .header.header--fixed {
        right: 0;
        z-index: 99;
    }
}
@media screen and (max-width: 992px) {
    .team__member {
        background-size: 25%;
    }
    .team__member .box .team__member__info {
        margin-top: 340px;
    }
}
@media screen and (max-width: 720px) {
    .hamburger {
        display: block;
    }
    .main-menu {
        display: none;
    }
    .top-section {
        background: url('../img/top-img.jpg') no-repeat 0 0;
        background-size: 650px 680px;
        background-attachment: fixed;
        height: 100%;
    }
    .team__member {
        background-size: 30%;
    }
    .team__member .box .team__member__info {
        margin-top: 260px;
    }
    .footer--sticky {
        position: relative;
    }
}

@media screen and (max-width: 480px) {
    .team__member {
        background-size: 46%;
    }
}

@media screen and (max-width: 400px) {
    .team__member {
        background-size: 55%;
    }
}
